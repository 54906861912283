import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import logo from '../images/logo.png'
import '../styles/navbar.css'

const Navbar = ({ path }) => {
  const [responsiveClass, setResponsiveClass] = useState('')
  const navBarActiveClass = 'active'

  function toggleHamburger() {
    if (responsiveClass === '') {
      setResponsiveClass('responsive')
    } else {
      setResponsiveClass('')
    }
  }

  return (
    <nav className={`${responsiveClass} `} role="navigation" aria-label="main-navigation">
      <div className="Nav container-full">
        <div className="Nav-logo">
          <Link to="/" title="Logo" className="logo-link">
            <img src={logo} alt="logo" style={{ width: '120px' }} />
          </Link>
          {/* Hamburger menu */}
          <div
            className={`burgerbars ${navBarActiveClass}`}
            data-target="navMenu"
            onClick={() => toggleHamburger()}
            role="button"
            tabIndex={0}
          >
            <span />
          </div>
        </div>
        <div className="Nav-links">
          <div className="Link-container">
            <div className="btn-open-sublink" />

            <div className={`Nav-item Nav-subitems ${path && path.includes('/about') && 'active'}`}>
              <div className="Nav-item-text">About</div>
            </div>

            <ul className="Nav-sublinks">
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/about/our-beliefs">Our Beliefs</Link>
              </li>
              <li>
                <Link to="/about/mi-standards">MI Standards</Link>
              </li>
              <li>
                <Link to="/about/leadership">Leadership Team</Link>
              </li>
              <li>
                <Link to="/about/our-team">Staff Team</Link>
              </li>
              <li>
                <Link to="/about/states-territories">States and Territories</Link>
              </li>
              <li>
                <Link to="/about/ourpartners">Our Partners</Link>
              </li>
            </ul>
          </div>

          <div className="Link-container">
            <div className="btn-open-sublink" />

            <div
              className={`Nav-item Nav-subitems ${path && path.includes('/membership') && 'active'
                }`}
            >
              <div className="Nav-item-text">Membership</div>
            </div>

            <ul className="Nav-sublinks">
              <li>
                <Link to="/membership/mi-members-and-associates">Members and Associates</Link>
              </li>
              <li>
                <Link to="/membership/benefits">Member Benefits</Link>
              </li>
              <li>
                <Link to="/membership/join">Join MI</Link>
              </li>
              <li>
                <Link to="/membership/fees">Fees</Link>
              </li>
              <li>
                <Link to="/membership/croydon">Croydon Declaration</Link>
              </li>
            </ul>
          </div>

          <div className="Link-container">
            <div className="btn-open-sublink" />
            <div
              className={`Nav-item Nav-subitems ${path &&
                (path.includes('/mi-networks') ||
                  path.includes('/serve-on-mission') ||
                  path.includes('/give-to-mission') ||
                  path.includes('/prayer-for-mission')) &&
                'active'
                }`}
            >
              <div className="Nav-item-text">Get involved</div>
            </div>

            <ul className="Nav-sublinks">
              <li>
                <Link to="/mi-networks">MI Networks</Link>
              </li>
              <li>
                <Link to="/serve-on-mission">Serve on Mission</Link>
              </li>
              <li>
                <Link to="/give-to-mission">Give to Mission</Link>
              </li>
              <li>
                <Link to="/prayer-for-mission">Prayer for Mission</Link>
              </li>
            </ul>
          </div>

          <div className="Link-container">
            <div className="btn-open-sublink" />

            <div
              className={`Nav-item Nav-subitems ${path && path.includes('/events') && 'active'}`}
            >
              <div className="Nav-item-text">Events</div>
            </div>

            <ul className="Nav-sublinks">
              <li>
                <Link to="/events">Events</Link>
              </li>
              <li>
                <Link to="/events/training">Training</Link>
              </li>
            </ul>
          </div>

          <div className="Link-container">
            <div className="btn-open-sublink" />

            <div
              className={`Nav-item Nav-subitems ${path && path.includes('/resources') && 'active'}`}
            >
              <div className="Nav-item-text">Resources</div>
            </div>
            <ul className="Nav-sublinks">
              <li>
                <Link to="/resources">Open Resources</Link>
              </li>
              <li>
                <Link to="/resources/helping-professionals">Helping Professionals</Link>
              </li>
              <li>
                <Link to="/resources/book-summaries">Book Summaries</Link>
              </li>
              <li>
                <Link to="/resources/blog">MI Blog</Link>
              </li>
              <li>
                <Link to="/resources/podcasts">Podcasts</Link>
              </li>
              <li>
                <Link to="/resources/store">Resource Store</Link>
              </li>
              <li>
                <Link to="/member-only-resources">Member-Only Resources</Link>
              </li>
            </ul>
          </div>

          <div className="Link-container">
            <Link className="Nav-item Nav-subitems" to="/contact" activeClassName="active">
              <div className="Nav-item-text">Contact Us</div>
            </Link>
          </div>

          <div className="Link-container menu-nav-login">
            <Link to="/donate"><div className="Nav-item-text">Donate</div></Link>
          </div>
        </div>

        <div className="nav-icon" onClick={toggleHamburger} role="button" tabIndex={0}>
          <div />
        </div>

        <div className="Nav-login">
          <Link to="/donate"><div className="btn btn-call-to-action btn-secondary">Donate</div></Link>
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  path: PropTypes.string
}

Navbar.defaultProps = {
  path: ''
}

export default Navbar
