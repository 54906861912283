import React from 'react'
import PropTypes from 'prop-types'
import Navbar from './navbar'
import Footer from './footer'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/layout.css'

const Layout = ({ children, editPageId, path }) => {
  return (
    <>
      <div className="content">
        <Navbar path={path} />
        <main>{children}</main>
      </div>
      <Footer editPageId={editPageId} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  editPageId: PropTypes.string,
  path: PropTypes.string
}

Layout.defaultProps = {
  editPageId: '',
  path: ''
}

export default Layout
