import React from 'react'
import PropTypes from 'prop-types'

const SectionFooter = ({ sectionTitle, sectionBody }) => {
  return (
    <div>
      <h2>{sectionTitle}</h2>
      <div>{sectionBody}</div>
    </div>
  )
}

export default SectionFooter

SectionFooter.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  sectionBody: PropTypes.node.isRequired
}
